<template>
    <BT-Dialog
        v-if="balanceItem != null"
        :getOnOpenAsync="openOverview"
        hideButton
        :label="balanceItem.product.productName"
        :openToggle="openToggle"
        width="550">
        <template v-slot="{ item }">
            <v-container v-if="item != null && item.needs != null && item.supplies != null">
                <v-row class="mt-4 py-0">
                    <v-col cols="4"><strong>Description</strong></v-col>
                    <v-col cols="4" class="text-center"><strong>Plan</strong></v-col>
                    <v-col cols="4" class="text-center"><strong>Actual</strong></v-col>
                </v-row>
                <v-row class="my-0 py-0">
                    <v-col cols="4"></v-col>
                    <v-col cols="2" class="text-right"><strong>Gross</strong></v-col>
                    <v-col cols="2" class="text-right"><strong>Net</strong></v-col>
                    <v-col cols="2" class="text-right"><strong>Gross</strong></v-col>
                    <v-col cols="2" class="text-right"><strong>Net</strong></v-col>
                </v-row>
                <v-row class="my-0 py-0">
                    <v-col cols="4"><strong>Current Spare</strong></v-col>
                    <v-col cols="2" class="text-right">{{ item.spare | toDisplayNumber }}</v-col>
                    <v-col cols="2" class="text-right">{{ item.spare | toDisplayNumber }}</v-col>
                    <v-col cols="2" class="text-right">{{ item.spare | toDisplayNumber }}</v-col>
                    <v-col cols="2" class="text-right">{{ item.spare | toDisplayNumber }}</v-col>
                </v-row>
                <v-row>
                    <v-divider class="my-3 mr-3" />
                    <span>{{ item.needs.length }} Need{{ item.needs.length == 1 ? '' : 's' }}</span>
                    <v-divider class="my-3 ml-3" />
                </v-row>
                <v-row v-for="(item, index) in item.needs" :key="index + 'n'">
                    <v-col cols="4">
                        <span v-if="item.date != null">{{ item.date | toDayMonth }} - </span>{{ item.type }}
                    </v-col>
                    <v-col cols="2" class="text-right">({{ item.quantity | toDisplayNumber }})</v-col>
                    <v-col cols="2" class="text-right">{{ item.quantityNet | toDisplayNumber }}</v-col>
                    <v-col cols="2" class="text-right">({{ item.actual | toDisplayNumber }})</v-col>
                    <v-col cols="2" class="text-right">{{ item.actualNet | toDisplayNumber }}</v-col>
                </v-row>
                <v-row>
                    <v-divider class="my-3 mr-3" />
                    <span>{{ item.supplies.length }} {{ item.supplies.length == 1 ? 'Supply' : 'Supplies' }}</span>
                    <v-divider class="my-3 ml-3" />
                </v-row>
                <v-row v-for="(item, index) in item.supplies" :key="index + 's'">
                    <v-col cols="4">
                        <span v-if="item.date != null">{{ item.date | toDayMonth }} - </span>{{ item.type }}
                    </v-col>
                    <v-col cols="2" class="text-right">{{ item.quantity | toDisplayNumber }}</v-col>
                    <v-col cols="2" class="text-right">{{ item.quantityNet | toDisplayNumber }}</v-col>
                    <v-col cols="2" class="text-right">{{ item.actual | toDisplayNumber }}</v-col>
                    <v-col cols="2" class="text-right">{{ item.actualNet | toDisplayNumber }}</v-col>
                </v-row>
            </v-container>
        </template>
    </BT-Dialog>
</template>

<script>
import { firstBy } from 'thenby';

export default {
    name: 'BT-Stock-Balance-Overview',
    components: {
        BTDialog: () => import('~components/BT-Dialog.vue')
    },
    props: {
        balanceItem: {
            type: Object,
            default: null
        },
        locationID: {
            type: String,
            default: null
        },
        openToggle: {
            type: Boolean,
            default: false
        },
        transfers: {
            type: Array,
            default: null
        },
        trackedItems: {
            type: Array,
            default: null
        },
        batches: {
            type: Array,
            default: null
        }
    },
    methods: {
        getDay(days, dateStr) {
            var d = this.$BlitzIt.auth.formUTCDateTime(dateStr).startOf('day');
            var day = days.find(z => z.date == d);

            if (day == null) {
                day = {
                    date: d,
                    make: 0,
                    needs: 0,
                    transitIn: 0,
                    transitInActual: 0,
                    transitOut: 0,
                    transitOutActual: 0,
                    useActual: false,

                    makeRT: 0,
                    transitInRT: 0,
                    transitOutRT: 0
                };

                days.push(day);
            }

            return day;
        },
        async openOverview() {
            var r = {
                picked: 0,
                spare: 0,
                onOrder: 0,
                purchasing: 0,
                onHand: 0,
                days: [] //{ date, make, needs, transitIn, transitInActual, transitOut, transitOutActual }
            };

            var n = {
                originalOnOrder: 0,
                originalOnHand: 0,
                originalSpare: 0,
                originalPurchasing: 0,
                onOrder: 0,
                onHand: 0,
                spare: 0,
                purchasing: 0,
                needs: [],
                supplies: []
            };

            if (this.isLengthyArray(this.batches)) {
                this.batches.filter(z => z.productID == this.balanceItem.productID).forEach(b => {
                    var day = this.getDay(r.days, b.dom);
                    day.make += b.quantityGoal;

                    n.supplies.push({
                        date: this.$BlitzIt.auth.formRawTZ(b.dom).startOf('day'),
                        quantity: b.quantityGoal,
                        quantityNet: 0,
                        actual: b.quantityGoal,
                        actualNet: 0,
                        type: 'Make'
                    })
                })
            }

            if (this.isLengthyArray(this.trackedItems)) {
                this.trackedItems.filter(z => z.productID == this.balanceItem.productID).forEach(t => {
                    r.onOrder += t.consigned;
                    r.purchasing += t.onOrder;
                    r.onHand += t.inStock;

                    n.originalOnOrder += t.consigned;
                    n.originalOnHand += t.inStock;
                    n.originalPurchasing += t.onOrder;

                    n.onOrder += t.consigned;
                    n.onHand += t.inStock;
                    n.purchasing += t.onOrder;
                })
            }

            if (this.isLengthyArray(this.transfers)) {
                this.transfers.filter(z => z.performedOn == null).forEach(t => {
                    var day = this.getDay(r.days, t.dueDate);

                    if (t.destinationLocationID == this.locationID) {
                        //is inbound
                        var tInActual = t.consignmentEntryItems.filter(z => z.productID == this.balanceItem.productID).sum(z => z.quantity);
                        var tIn = t.consignmentOrderItems.filter(z => z.productID == this.balanceItem.productID).sum(z => z.quantity);
                        
                        day.transitInActual += tInActual;
                        day.transitIn += tIn; 

                        n.supplies.push({
                            date: this.$BlitzIt.auth.formRawTZ(t.dueDate).startOf('day'),
                            quantity: tIn,
                            quantityNet: 0,
                            actual: tInActual,
                            actualNet: 0,
                            type: 'Transit In'
                        });
                    }
                    else if (t.departureLocationID == this.locationID) {
                        //is outbound
                        var tOutActual = t.consignmentEntryItems.filter(z => z.productID == this.balanceItem.productID).sum(z => z.quantity);
                        var tOut = t.consignmentOrderItems.filter(z => z.productID == this.balanceItem.productID).sum(z => z.quantity);

                        // r.onOrder -= tOut;
                        day.transitOutActual += tOutActual;
                        day.transitOut += tOut;

                        n.needs.push({
                            date: this.$BlitzIt.auth.formRawTZ(t.dueDate).startOf('day'),
                            quantity: tOut,
                            quantityNet: 0,
                            actual: tOutActual,
                            actualNet: 0,
                            type: 'Transit Out'
                        });

                        n.onOrder -= tOut;
                        n.onHand -= tOut;
                    }
                })
            }

            var inStockRes = await this.$BlitzIt.store.getAll('stock-items', { locationID: this.locationID, productID: this.balanceItem.productID });
            if (this.isLengthyArray(inStockRes)) {
                var inStock = inStockRes.sum(z => z.available);
                r.spare = inStock;
                n.originalSpare += inStock;
            }

            n.needs.push({
                date: null,
                quantity: n.onOrder,
                quantityNet: 0,
                actual: n.onOrder,
                actualNet: 0,
                type: 'Orders'
            })

            this.refreshRunningTotal(n);

            return n;
        },
        refreshRunningTotal(item) {
            //var picked = item.onHand - item.spare;
            var onOrder = item.originalOnOrder;
            var onHand = item.originalOnHand;

            item.needs.sort(firstBy(x => x.date != null ? x.date.toMillis() : 0));
            item.supplies.sort(firstBy(x => x.date != null ? x.date.toMillis() : 0));

            var plannedRT = item.originalSpare;
            var actualRT = item.originalSpare;

            item.needs.forEach(n => {
                plannedRT -= n.quantity;
                n.quantityNet = plannedRT;

                actualRT -= n.actual;
                n.actualNet = actualRT;
            });

            item.supplies.forEach(n => {
                plannedRT += n.quantity;
                n.quantityNet = plannedRT;

                actualRT += n.actual;
                n.actualNet = actualRT;
            });

            item.onOrder = onOrder;
            item.onHand = onHand;
            item.spare = item.originalSpare;
        }
    }
}
</script>